<template>
  <div>
    <navbar />
    <main class="profile-page">
      <section class="relative block h-500-px">
        <!-- <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          style="
            background-image: url('https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80');
          "
        > -->
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          style="background-color: #000000;"
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-50 bg-black"
          ></span>
        </div>
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
              style="fill:#202020;"
            ></polygon>
          </svg>
        </div>
      </section>
      <!-- <section class="relative py-16 bg-blueGray-200"> -->
      <section class="relative py-16" style="background-color:#202020">
        <div class="container mx-auto px-4" >
          <div
            class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64"
            style="background: #303030;"
          >
            <div class="px-6" style="background-color:#303030;">
              <div class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"
                >
                  <div class="relative">
                    <img
                      alt="..."
                      :src="team2"
                      class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                    />
                  </div>
                </div>
                <!-- <div
                  class="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center"
                >
                  <div class="py-6 px-3 mt-32 sm:mt-0">
                    <button
                      class="bg-emerald-500 active:bg-emerald-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                      type="button"
                    >
                      Connect
                    </button>
                  </div>
                </div> -->
                <!-- <div class="w-full lg:w-4/12 px-4 lg:order-1">
                  <div class="flex justify-center py-4 lg:pt-4 pt-8">
                    <div class="mr-4 p-3 text-center">
                      <span
                        class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
                      >
                        22
                      </span>
                      <span class="text-sm text-blueGray-400">Friends</span>
                    </div>
                    <div class="mr-4 p-3 text-center">
                      <span
                        class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
                      >
                        10
                      </span>
                      <span class="text-sm text-blueGray-400">Photos</span>
                    </div>
                    <div class="lg:mr-4 p-3 text-center">
                      <span
                        class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
                      >
                        89
                      </span>
                      <span class="text-sm text-blueGray-400">Comments</span>
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="mt-30" >
                <form @submit.prevent="Logout">
                <div style="display: grid; grid-template-columns: auto auto;margin-top:100px;">
                    <div style="grid-column-start: 1;">
                      <h3
                      class="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2" style="color:white;"
                      >
                      Hello Admin,
                      </h3>
                    </div>
                    <div style="grid-column-start: 2; grid-auto-flow: column; justify-content: end; text-align:right;">
                      <button class="logout" style="color:white;" @click="Logout">Logout</button>
                    </div>
                </div>
                </form>
                
                <!-- <div
                  class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase"
                >
                  <i
                    class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"
                  ></i>
                  Los Angeles, California
                </div>
                <div class="mb-2 text-blueGray-600 mt-10">
                  <i
                    class="fas fa-briefcase mr-2 text-lg text-blueGray-400"
                  ></i>
                  Solution Manager - Creative Tim Officer
                </div>
                <div class="mb-2 text-blueGray-600">
                  <i
                    class="fas fa-university mr-2 text-lg text-blueGray-400"
                  ></i>
                  University of Computer Science
                </div> -->
              </div>
              <!-- <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full lg:w-9/12 px-4">
                    <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      An artist of considerable range, Jenna the name taken by
                      Melbourne-raised, Brooklyn-based Nick Murphy writes,
                      performs and records all of his own music, giving it a
                      warm, intimate feel with a solid groove structure. An
                      artist of considerable range.
                    </p>
                    <a
                      href="javascript:void(0)"
                      class="font-normal text-emerald-500"
                    >
                      Show more
                    </a>
                  </div>
                </div>
              </div> -->
              <div class="w-full mb-12 px-4">
                <!-- <card-table /> -->
                <!-- <Tabs/> -->
                <CardTable />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/AuthNavbar.vue";
import FooterComponent from "@/components/Footer.vue";

// import team2 from "@/assets/img/team-2-800x800.jpg";
import team2 from "@/assets/logo2.png";
import CardTable from "@/components/CardTable.vue";
// import Tabs from "@/components/Tabs.vue";

import {onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { ref } from "vue";

// import firebase from "firebase";
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

export default {
  setup () {
    const router = useRouter();
    const name = ref("");

    onBeforeMount(()=>{
      const user = firebase.auth().currentUser;
        if(user) {
          name.value = user.email.split("@")[0];
        }
      firebase.auth().onAuthStateChanged((user)=>{
        if(!user){
          router.replace("/login");
        }
      })
    })
    
    const Logout = ()=> {
      firebase
        .auth()
        .signOut()
        .then(()=>alert("Logged Out"))
        .catch(err=>alert(err.message));
    }

    return {
      name,
      Logout
    }
  },

  data() {
    return {
      team2,
    };
  },
  components: {
    Navbar,
    FooterComponent,
    CardTable,
    // Tabs,
  },
};
</script>
